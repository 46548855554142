.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.flex-column {
  flex-direction: column;
}
.pointer {
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden !important;
}
.text-black {
  color: #232323;
}
.text-error {
  color: #f00;
}
.text-grey {
  color: #999999;
}
.text-white {
  color: #ffffff;
}
.text-blue {
  color: #4993dd;
}
.container {
  max-width: 2000px;
  padding: 0 60px;
  margin: 0 auto;
  width: 100%;
}

.hover:hover {
  cursor: pointer;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.align-items-start {
  align-items: start;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-end {
  justify-content: end !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-nowrap {
  white-space: nowrap;
}
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
}
.list-unstyled {
  list-style-type: none;
}
.font-500 {
  font-weight: 500;
}
.font-400 {
  font-weight: 400;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 20px;
}
.gap-4 {
  gap: 1.5rem;
}
.gap-5 {
  gap: 3rem;
}
.gap-6 {
  gap: 56px;
}
.text-center {
  text-align: center !important;
}
.text-end {
  text-align: right;
}
.text-start {
  text-align-last: left;
}
.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}
.download-template {
  margin-left: auto;
  cursor: pointer;
}
.download-template path {
  fill: #1976d2;
}
.products-modal-button {
  border: 1px solid #1976d2 !important;
}
.drawer-wrapper {
  padding: 32px 24px;
  min-width: 900px;
  max-width: 900px;
}
.drawer-close-icon svg {
  position: fixed;
  top: 15px;
  right: 30px;
  display: block;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}
.clickable-td {
  cursor: pointer;
  background-color: #fff;
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
}
.carReport .search-box-container {
  margin-top: 15px;
}
.custom-drawer {
  width: 380px;
}
.custom-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  width: 400px;
}
.custom-label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #32324d;
  padding: 5px 0;
}
.custom-label span {
  color: red;
}
.popover-container .MuiPaper-root {
  border-radius: 8px;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.5);
  padding: 14px 10px;
  transform: translate(-77px, 4px);
  width: 170px;
}
.popover span {
  display: block;
  color: #555;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 2px;
}
.popover .change-market {
  display: block;
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
.popover .logout {
  display: block;
  color: #dd4b4b;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.action-wrapper {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
  min-height: calc(100vh - 85px);
}
